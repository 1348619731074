export const getImageRatio = (objectUrl: string) =>
  new Promise<number>((resolve) => {
    // Generate new image element
    const img = new Image()
    img.src = objectUrl

    // On image loage, calculate image ratio
    img.onload = () => resolve(img.width / img.height)
  })

export const generateIconURL = (name: string, state: string) => `/icons/button/${name}/${state}.svg`

export const isENS = (input: string): boolean => {
  // Speed up by exiting early if string doesn't end in .eth
  if (!input.match(/.eth$/g)) return false
  // Else match whole express
  return !!input.match(/^([a-z0-9]+.)+eth$/g)
}

export const isAddress = (input: string): boolean => !!input.match(/^0x[a-fA-F0-9]{40}$/g)
export const truncateAddress = (address: string): string => `${address.slice(0, 6)}...${address.slice(-4)}`

export const sortByProperty = (a: Record<string, number>, b: Record<string, number>, property: string): number => {
  if (a[property] > b[property]) return 1
  else if (a[property] < b[property]) return -1
  else return 0
}

export const isPortrait = (): boolean => window.innerHeight > window.innerWidth
export const isBrowser = (): boolean => typeof window !== 'undefined'
